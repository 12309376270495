import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Input,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { colors } from "@/constants/colors";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Transition } from "@/pages";
import TransactionTable from "./TransactionTable2";
// import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5QrcodeResult } from "html5-qrcode/esm/core";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { protocol } from "@/utils/frontend";
import moment from "moment";
import { Html5QrcodeScanner } from "html5-qrcode";

interface Props {
    data: any
    handleLogout: () => void
    host: string
    verify: (token: string) => void
}

interface User {
    id: number;
    name: string;
    email: string;
    credit: number
}

const dummyUser = { "id": 1, "name": "John Doe", "email": "johndoe@gmail.com", "credit": 31 }

export default function Dashboard({ data, handleLogout, host, verify }: Props) {
    // const [role, setRole] = useState<ROLES>("ADMIN")
    const [dialogVendor, setOpenDialogVendor] = useState(false);
    const [dialogTx, setOpenDialogTx] = useState(false);
    const [dialogAdmin, setOpenDialogAdmin] = useState(false);
    const [dialogCredit, setOpenDialogCredit] = useState(false);
    const [dialogRegister, setDialogRegister] = useState(false);
    const [dialogVendorMenu, setDialogVendorMenu] = useState(false);
    const [openScan, setopenScan] = useState(false);
    const [loading, setloading] = useState(false)
    const [user, setuser] = useState<User | null>(null)
    const [amount, setamount] = useState(0)
    const [vendor, setVendor] = useState({
        username: "",
        password: "",
        name: ""
    })

    const init = () => {
        localStorage.setItem('scanned', "0");
        setamount(0);
        setuser(null);
        setOpenDialogTx(false);
        setOpenDialogCredit(false);
        setDialogVendorMenu(false);
        setVendor({
            username: "",
            password: "",
            name: ""
        })
        if (data.admin.role == 'ADMIN') {
            setOpenDialogAdmin(true)
        } else if (data.admin.role == 'VENDOR') {
            setOpenDialogVendor(true);
        } else if (data.admin.role == 'REGIST') {
            setDialogRegister(true);
        }


        // setOpenDialogCredit(true)
    }

    const handleOpenDialogTx = () => {
        setOpenDialogAdmin(false);
        setOpenDialogVendor(false);
        setDialogRegister(false);

        setOpenDialogTx(true);
    }

    const handleOpenDialogCredit = (user: User) => {
        setOpenDialogAdmin(false);
        setOpenDialogVendor(false);
        setOpenDialogTx(false);

        setuser(user);
        setOpenDialogCredit(true);

        localStorage.setItem('scanned', "1");
    }

    const handleOpenDialogVendorMenu = () => {
        setOpenDialogAdmin(false);
        setOpenDialogVendor(false);
        setOpenDialogTx(false);
        setOpenDialogCredit(false);
        setDialogRegister(false);

        setDialogVendorMenu(true);
        setVendor({
            username: "",
            password: "",
            name: ""
        })
    }

    const handleCloseDialogCredit = () => {
        setuser(null);
        init();
    }

    const handleCloseDialogVendorMenu = () => {
        setDialogVendorMenu(false);
        init();
    }

    const handleReport = () => {
        const headers = ['id', 'amount', 'date', 'user', 'vendor', 'email', 'phone'];

        const transactions = data.transactions.map((a: any) => {
            const values: any[] = [];

            Object.entries(a).map((b: any) => {
                if (data.admin.role == 'VENDOR' && b[0] == 'amount') {
                    values.push(Math.abs(b[1]))
                } else {
                    values.push(b[1])
                }
            })

            return values;
        })

        transactions.unshift(headers);

        const csvContent = "data:text/csv;charset=utf-8," + transactions.map((e: any) => e.join(",")).join("\n");
        const encodeUri = encodeURI(csvContent);

        const link = document.createElement("a")
        link.setAttribute("href", encodeUri)
        link.setAttribute("download", `REPORT-${moment(new Date).format('DD-MM-YY')}`)
        document.body.appendChild(link)
        link.click();
    }


    const handleSave = async () => {
        setloading(true);

        try {
            const token = localStorage.getItem('token');

            await axios.post(`${protocol}://${host}/api/balance`, {
                user_id: user?.id,
                amount
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            toast.success('Successfully change user credit')

            init();
            verify(token || "");
        } catch (error: any) {
            toast.error(error.response.data.message)
        } finally {
            setloading(false);
        }
    }

    const handleAddVendor = async () => {
        setloading(true);

        try {
            const token = localStorage.getItem('token');

            if (token) {
                await axios.post(`${protocol}://${host}/api/add-vendor`, vendor, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            }

            toast.success('Successfully added vendor');

            init();
        } catch (error: any) {
            toast.error(error.response.data.message)
        } finally {
            setloading(false);
        }
    }

    const onNewScanResult = useCallback(
        async (decodedText: string, result: Html5QrcodeResult) => {
            // handle decoded results here
            // toast.success('scanning')
            if (user != null) return;

            const scanned = localStorage.getItem('scanned');
            if (scanned == "1") return;

            // console.log('user', user, scanned);
            setloading(true);
            try {
                if (user == null) {
                    const response = await axios.post(`${protocol}://${host}/api/scan-qr`, {
                        hash: decodedText
                    })

                    localStorage.setItem('scanned', "1");
                    handleOpenDialogCredit(response.data)
                };
            } catch (error: any) {
                toast.error(error.response.data.message);
            } finally {
                setloading(false)
            }
        },
        [user],
    );

    useEffect(() => {
        init()
    }, [])

    const handleInputQR = () => {
        let qr = prompt('Enter QR Code');
        console.log(qr);

        setloading(true);
        axios.post(`${protocol}://${host}/api/scan-qr`, {
            hash: qr
        }).then((response) => {
            console.log(response);

            if (response.data) {
                handleOpenDialogCredit(response.data)
            } else {
                toast.error("Invalid QR Code");
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        }).finally(() => {
            setloading(false)
        })
    }

    const handleGenerateQR = () => {
        let amount = parseInt(prompt('Amount of QR Code to generate:')!);
        if (amount) {
            setloading(true);

            let epochTime = Math.floor(Date.now() / 1000);
            let promises = [];
            for (let i = 0; i < amount; i++) {
                promises.push(axios.post(`${protocol}://${host}/api/register`, {
                    email: "generated-" + epochTime + "-" + (i+1) + "@squaretic.com", 
                    name: "Generated QR", 
                    generated: true
                }));
            }

            Promise.all(promises).then((responses) => {
                //console.log(responses);
                for (let i = 0; i < responses.length; i++) {
                    window.open("/qr?hash=" + responses[i].data + "&count=" + (i+1), '_blank');
                }
            })
        }
    }

    const handleOpenScan = () => {
        localStorage.setItem('scanned', "0");
        let reader, container;

        if (data.admin.role == 'VENDOR') {
            reader = 'reader';
            container = 'vendor-content'
        } else if (data.admin.role == 'ADMIN') {
            reader = 'reader2';
            container = 'admin-content'
        } else if (data.admin.role == 'REGIST') {
            reader = 'reader3';
            container = 'regist-content';
        }

        if (reader && container) {
            const html5qrcode = new Html5QrcodeScanner(reader, { fps: 10 }, undefined);
            if (openScan) {
                setopenScan(false);
                const getReader = document.getElementById(reader);
                if (getReader) {
                    // console.log('remove', getReader);
                    html5qrcode.clear();
                    getReader.remove();
                }
            } else {
                const div_reader = document.createElement('div');
                div_reader.setAttribute('id', reader);

                const getContainer = document.getElementById(container);
                if (getContainer) {
                    // console.log('append', div_reader);
                    getContainer.appendChild(div_reader)
                    setopenScan(true);
                    // const config = { fps: 10, qrbox: { width: 250, height: 250 } }
                    setTimeout(() => {
                        html5qrcode.render(onNewScanResult, undefined)
                        // html5qrcode.start({ facingMode: "environment" }, {
                        //     fps: 30
                        // }, onNewScanResult, () => { })
                    }, 100);
                }
            }
        }
    }


    return (
        <>
            <Dialog
                open={dialogTx}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'md'}
                PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px' } }}
            >
                <DialogTitle sx={{ color: colors.BLUE }} textAlign={'center'}>
                    <Typography fontSize={'32px'}>{data.vendor?.name}</Typography>
                </DialogTitle>
                <DialogContent style={{ padding: '20px 12px' }}>
                    <TransactionTable data={data.transactions} withRevert host={host} withUser role={data.admin.role} verify={verify} />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', margin: '0 4px' }}>
                    <Stack width={'100%'} gap={'10px'}>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={init}>Back</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogVendor}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'md'}
                PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px' } }}
            >
                <DialogTitle sx={{ color: colors.BLUE }} textAlign={'center'}>
                    <Typography fontSize={'32px'}>{data.vendor?.name}</Typography>
                </DialogTitle>
                <DialogContent style={{ padding: '20px 12px' }} id="vendor-content">
                    <Stack flexDirection={'row'} justifyContent={'center'} sx={{ display: openScan ? 'none' : 'visible' }}>
                        <Box width={'50%'}>
                            <Typography textAlign={'center'} variant='h5' fontWeight={600} color={'black'}>{data.unique_customer}</Typography>
                            <Typography textAlign={'center'}>Unique Customers</Typography>
                        </Box>
                        <Divider orientation='vertical' flexItem />
                        <Box width={'40%'}>
                            <Typography textAlign={'center'} variant='h5' fontWeight={600} color={'black'}>{data.credits_earned}</Typography>
                            <Typography textAlign={'center'}>Credits Earned</Typography>
                        </Box>
                    </Stack>
                    <div id="reader"></div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', margin: '0 4px' }}>
                    <Stack width={'100%'} gap={'10px'}>
                        <Button onClick={handleOpenScan} variant='contained' fullWidth sx={{ borderRadius: 2 }} startIcon={
                            <img src="scan.svg" alt="" style={{ position: 'absolute', top: 5, left: 10 }} width={'10%'} />
                        }>
                            {openScan ? 'Close Camera' : 'Scan QR'}
                        </Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleInputQR}>Input QR</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleOpenDialogTx}>Transaction History</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleReport}>Generate Report</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleLogout}>Logout</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogAdmin}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'md'}
                PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px' } }}
            >
                <DialogTitle sx={{ color: colors.BLUE }} textAlign={'center'}>
                    <Typography fontSize={'32px'}>{data.admin.username}</Typography>
                </DialogTitle>
                <DialogContent style={{ padding: '20px 12px' }} id="admin-content">
                    <Stack flexDirection={'row'} justifyContent={'center'} sx={{ display: openScan ? 'none' : 'visible' }}>
                        <Box width={'50%'}>
                            <Typography textAlign={'center'} variant='h5' fontWeight={600} color={'black'}>{data.total_vendors}</Typography>
                            <Typography textAlign={'center'}>Vendors</Typography>
                        </Box>
                        <Divider orientation='vertical' flexItem />
                        <Box width={'40%'}>
                            <Typography textAlign={'center'} variant='h5' fontWeight={600} color={'black'}>{data.total_users}</Typography>
                            <Typography textAlign={'center'}>Total Users</Typography>
                        </Box>
                        <Divider orientation='vertical' flexItem />
                        <Box width={'40%'}>
                            <Typography textAlign={'center'} variant='h5' fontWeight={600} color={'black'}>{data.total_credits}</Typography>
                            <Typography textAlign={'center'}>Credits Earned</Typography>
                        </Box>
                    </Stack>
                    <div id="reader2"></div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', margin: '0 4px' }}>
                    <Stack width={'100%'} gap={'10px'}>
                        <Button onClick={handleOpenScan} variant='contained' fullWidth sx={{ borderRadius: 2 }} startIcon={
                            <img src="scan.svg" alt="" style={{ position: 'absolute', top: 7, left: 10 }} width={'10%'} />
                        }>
                            {openScan ? 'Close Camera' : 'Scan QR'}
                        </Button>
                        {/* <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleInputQR}>Input QR</Button> */}
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleGenerateQR}>Generate QR</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleOpenDialogTx}>Transaction History</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleReport}>Generate Report</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleOpenDialogVendorMenu}>Add Vendor</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleLogout}>Logout</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogRegister}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'md'}
                PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px' } }}
            >
                <DialogTitle sx={{ color: colors.BLUE }} textAlign={'center'}>
                    <Typography fontSize={'32px'}>{data.admin.username}</Typography>
                </DialogTitle>
                <DialogContent id="regist-content">
                    <div id="reader3"></div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', margin: '0 4px' }}>
                    <Stack width={'100%'} gap={'10px'}>
                        <Button onClick={handleOpenScan} variant='contained' fullWidth sx={{ borderRadius: 2 }} startIcon={
                            <img src="scan.svg" alt="" style={{ position: 'absolute', top: 7, left: 10 }} width={'10%'} />
                        }>
                            {openScan ? 'Close Camera' : 'Scan QR'}
                        </Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleInputQR}>Input QR</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleOpenDialogTx}>Transaction History</Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleLogout}>Logout</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogVendorMenu}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'md'}
                PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px' } }}
            >
                <DialogTitle sx={{ color: colors.BLUE, position: 'relative' }} textAlign={'center'}>
                    <IconButton sx={{ position: 'absolute', left: 0, top: 20, color: 'black' }} onClick={handleCloseDialogVendorMenu}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography fontSize={'32px'}>{data.admin.username}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Input onChange={(e) => setVendor(prev => ({
                        ...prev,
                        name: e.target.value
                    }))} disableUnderline={true} placeholder="Name" fullWidth
                        sx={{
                            background: colors.GREY, borderRadius: '10px',
                            marginBottom: '12px',
                            input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }
                        }} />


                    <Input onChange={(e) => setVendor(prev => ({
                        ...prev,
                        username: e.target.value
                    }))} disableUnderline={true} placeholder="Username" fullWidth
                        sx={{
                            background: colors.GREY, borderRadius: '10px',
                            marginBottom: '12px',
                            input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }
                        }} />

                    <Input onChange={(e) => setVendor(prev => ({
                        ...prev,
                        password: e.target.value
                    }))} disableUnderline={true} placeholder="Password" fullWidth type="password"
                        sx={{
                            background: colors.GREY, borderRadius: '10px',
                            marginBottom: '12px',
                            input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }
                        }} />

                    <LoadingButton variant="contained" fullWidth loading={loading} onClick={handleAddVendor}>Add Vendor</LoadingButton>
                </DialogContent>
            </Dialog>
            <Dialog
                open={dialogCredit}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'md'}
                PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px', width: '400px' } }}
            >
                <DialogTitle sx={{ color: colors.BLUE, position: 'relative' }} textAlign={'center'}>
                    <IconButton sx={{ position: 'absolute', left: 0, top: 20, color: 'black' }} onClick={handleCloseDialogCredit}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography fontSize={'32px'}>{user?.name}</Typography>
                </DialogTitle>
                <DialogContent style={{ padding: '20px 12px' }}>
                    <TableContainer>
                        <Table sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                padding: 0,
                                textAlign: 'right'
                            },
                            margin: '0 0 30px 0'
                        }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontSize={'20px'} fontWeight={400} width={150}>Credit Balance</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize={'32px'} fontWeight={600} color={colors.BLUE}>{user?.credit}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontSize={'20px'} fontWeight={400} width={150}>Change</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize={'32px'} fontWeight={600} color={colors.RED}>{amount}</Typography>
                                    </TableCell>
                                </TableRow><TableRow>
                                    <TableCell>
                                        <Typography fontSize={'20px'} fontWeight={400} width={150}>Final Balance</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {user && (
                                            <Typography fontSize={'32px'} fontWeight={600} color={'black'} >{+user.credit + amount}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>


                    </TableContainer>
                    <Stack marginBottom={2} gap={'14px'} flexDirection={'row'} justifyContent={'center'}>
                        {(data.admin.role == 'ADMIN' || data.admin.role == 'REGIST') && (
                            <>
                                <Button variant='outlined' sx={{ borderRadius: 2, minWidth: '48px', padding: '5px' }} onClick={() => setamount((prev) => prev + 1)}>+1</Button>
                                <Button variant='outlined' sx={{ borderRadius: 2, minWidth: '48px', padding: '5px' }} onClick={() => setamount((prev) => prev + 10)}>+10</Button>
                            </>
                        )}
                        {(data.admin.role == 'ADMIN' || data.admin.role == 'VENDOR') && (
                            <>
                                <Button variant='outlined' sx={{ borderRadius: 2, minWidth: '48px', padding: '5px' }} onClick={() => setamount((prev) => prev - 1)}>-1</Button>
                                <Button variant='outlined' sx={{ borderRadius: 2, minWidth: '48px', padding: '5px' }} onClick={() => setamount((prev) => prev - 10)}>-10</Button>
                            </>
                        )}
                    </Stack>
                    <Button variant='outlined' fullWidth sx={{ borderRadius: 2 }} color='error' onClick={() => setamount(0)}>Reset</Button>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', margin: '0 4px' }}>
                    <Stack width={'100%'} gap={'10px'}>
                        <LoadingButton loading={loading} variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleSave}>Save</LoadingButton>
                    </Stack>
                </DialogActions>
            </Dialog >
        </>
    )
}