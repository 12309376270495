import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, Typography } from "@mui/material";
import { Transition } from ".";
import { colors } from "@/constants/colors";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { GetServerSideProps } from "next";
import { toast } from "react-toastify";
import Dashboard from "@/components/Dashboard";
import { protocol } from "@/utils/frontend";
import moment from "moment";

type Props = { host: string };

const ariaLabel = { 'aria-label': 'description' };
export default function Login({ host }: Props) {
    const [loading, setloading] = useState(false);
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [auth, setauth] = useState<{} | null>(null)

    const init = async (token: string) => {
        try {
            const verify = await axios.post(`${protocol}://${host}/api/verify`, undefined, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const transactions = verify.data.transactions.map((a: any) => {
                let vendor_name = ""

                if (a.admin?.role == 'VENDOR' && a.admin.vendor?.name) {
                    vendor_name = a.admin.vendor.name;
                } else if (a.admin) {
                    vendor_name = a.admin.username
                }

                return {
                    id: a.id,
                    amount: a.amount,
                    tx_date: moment(a.tx_date).format("DD-MM-Y"),
                    user_name: a.user.name,
                    vendor_name,
                    email: a.user.email,
                    phone: a.user.contact
                }
            })

            setauth({ ...verify.data, transactions });
        } catch (error: any) {
            toast.error(error.response.data.message)
        }
    }

    const handleLogin = async () => {
        setloading(true);
        try {
            const response = await axios.post(`${protocol}://${host}/api/login`, {
                username, password
            })
            const token = response.data.token
            localStorage.setItem('token', token);
            await init(token)

        } catch (error: any) {
            toast.error(error.response.data.message)
        } finally {
            setloading(false);
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        setauth(null);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            init(token)
        }

    }, [])


    return (<>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'md'}
            PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px', display: auth ? 'none' : 'visible' } }}
        >
            <DialogTitle sx={{ color: colors.BLUE }} textAlign={'center'}>
                <Typography fontSize={'32px'}>Squaretic</Typography>
            </DialogTitle>
            <DialogContent style={{ padding: '20px 12px' }}>
                <Input onChange={(e) => setusername(e.target.value)} disableUnderline={true} placeholder="Username" inputProps={ariaLabel} fullWidth
                    sx={{
                        background: colors.GREY, borderRadius: '10px',
                        marginBottom: '12px',
                        input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }
                    }} />

                <Input type="password" onChange={(e) => setpassword(e.target.value)} disableUnderline={true} placeholder="Password" inputProps={ariaLabel} fullWidth
                    sx={{
                        background: colors.GREY, borderRadius: '10px',
                        marginBottom: '32x',
                        input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }
                    }} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', margin: '0 4px' }}>
                <LoadingButton loading={loading} variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleLogin}>Login</LoadingButton>
            </DialogActions>
        </Dialog>

        {auth && (
            <Dashboard data={auth} handleLogout={handleLogout} host={host} verify={init} />
        )}
    </>)
}

export const getServerSideProps: GetServerSideProps<Props> =
    async context => ({ props: { host: context.req.headers.host || "localhost:3000" } });