import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Input } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import { protocol } from '@/utils/frontend';
import { toast } from 'react-toastify';

export interface TransactionInterface {
    id: number;
    amount: number;
    tx_date: string;
    vendor_name: string;
    user_name: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof TransactionInterface;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'tx_date',
        numeric: false,
        label: 'Date',
        disablePadding: false
    },
    {
        id: 'amount',
        numeric: false,
        label: 'Amount',
        disablePadding: false
    },
    {
        id: 'vendor_name',
        numeric: false,
        label: 'Vendor',
        disablePadding: false
    }
];

const DEFAULT_ORDER = 'desc';
const DEFAULT_ORDER_BY = 'id';
const DEFAULT_ROWS_PER_PAGE = 10;

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof TransactionInterface) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    withRevert: boolean;
    withUser: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, withRevert, withUser } =
        props;
    const createSortHandler =
        (newOrderBy: keyof TransactionInterface) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, newOrderBy);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {withUser && (
                    <TableCell>User</TableCell>
                )}
                {withRevert && (
                    <TableCell>Actions</TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    search: string;
    setSearch: (str: string) => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected, search, setSearch } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Transaction History
            </Typography>

            <Input placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)} />
        </Toolbar>
    );
}

interface Props {
    data: TransactionInterface[] | []
    withRevert?: boolean
    withUser?: boolean
    host?: string
    role?: string
    verify?: (token: string) => void
}

export default function TransactionTable2({ data, withRevert = false, host, withUser = false, role, verify }: Props) {
    const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState<keyof TransactionInterface>(DEFAULT_ORDER_BY);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [visibleRows, setVisibleRows] = React.useState<TransactionInterface[] | null>(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [rows, setRows] = React.useState<TransactionInterface[] | []>(data);
    const [search, setSearch] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleRevert = async (transaction_id: number) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (host && token && verify) {
                await axios.post(`${protocol}://${host}/api/revert`, {
                    transaction_id
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                verify(token);

                toast.success("Successfully reverted Transaction")
            }
        } catch (error) {
            const message = error instanceof AxiosError ? error.response?.data.message : "An unknown error occured.";

            toast.error(message);
        } finally {
            setLoading(false);
        }
    }

    const init = () => {
        let rowsOnMount = stableSort(
            rows,
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
        );
        rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
        );

        setVisibleRows(rowsOnMount);
    }

    React.useEffect(() => {
        setRows(data)
    }, [data]);

    React.useEffect(() => {
        init();
    }, [rows])

    const handleRequestSort = React.useCallback(
        (event: React.MouseEvent<unknown>, newOrderBy: keyof TransactionInterface) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            if (newOrderBy == 'tx_date') {
                newOrderBy = 'id';
            }

            const sortedRows = stableSort(rows, getComparator(toggledOrder, newOrderBy));
            const updatedRows = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
            setVisibleRows(updatedRows);
        },
        [order, orderBy, page, rowsPerPage],
    );

    const handleChangePage = React.useCallback(
        (event: unknown, newPage: number) => {
            setPage(newPage);

            const sortedRows = stableSort(rows, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
            );
            setVisibleRows(updatedRows);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows =
                newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

            const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
        },
        [order, orderBy, dense, rowsPerPage],
    );

    const handleChangeRowsPerPage = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);

            setPage(0);

            const sortedRows = stableSort(rows, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                0 * updatedRowsPerPage,
                0 * updatedRowsPerPage + updatedRowsPerPage,
            );
            setVisibleRows(updatedRows);
        },
        [order, orderBy],
    );

    React.useEffect(() => {
        const clone_data = data;
        const filter = search.toUpperCase();

        const filtered = clone_data.filter((a) => {
            const entries = Object.entries(a);
            let contains = false;
            for (let i = 0; i < entries.length; i++) {
                const element = entries[i];
                // console.log('element', (element[1] as string).toString().toUpperCase().indexOf(filter)> -1);
                if (element[1]) {
                    if ((element[1] as string).toString().toUpperCase().indexOf(filter) > -1) { contains = true; }
                }
            }

            return contains;
        })

        setRows(filtered)
    }, [search])


    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} search={search} setSearch={setSearch} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            withRevert={withRevert}
                            withUser={withUser}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row) => {
                                    return (
                                        <TableRow
                                            key={row.id}
                                        >
                                            <TableCell>{row.tx_date.toString()}</TableCell>
                                            <TableCell >
                                                {role == 'VENDOR' ? (
                                                    <Typography color={row.amount > 0 ? 'red' : 'green'}>{Math.abs(row.amount)}</Typography>
                                                ) : (
                                                    <Typography color={row.amount > 0 ? 'green' : 'red'}>{row.amount > 0 ? `+${row.amount}` : row.amount}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>{row.vendor_name}</TableCell>
                                            {withUser && (
                                                <TableCell>
                                                    {row.user_name}
                                                </TableCell>
                                            )}
                                            {withRevert && (
                                                <TableCell>
                                                    <LoadingButton size='small' variant='contained' color='error' loading={loading} onClick={() => handleRevert(row.id)}>Revert</LoadingButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </Box >
    );
}
